import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./components/Home";
import Dstp from "./components/Dstp";
import Pagla from "./components/Pagla";
import About from "./components/About";
import Contact from "./components/Contact";
import Mirpur from "./components/Mirpur";
import Rayerbazar from "./components/Rayerbazar";
import Uttara from "./components/Uttara";
import Zone4 from "./components/Zone4";
import Zone5 from "./components/Zone5";
import Zone6 from "./components/Zone6";
import Zone7 from "./components/Zone7";
import Zone8 from "./components/Zone8";
import Zone9 from "./components/Zone9";
import Zone10 from "./components/Zone10";



function App() {

  return (
    <>
      <BrowserRouter>
        <div style={{
          display: "flex",
          background: 'black',
          padding: '5px 0 5px 5px',
          fontSize: '20px'
        }}>
          <div style={{ margin: '10px' }}>
            <NavLink to="/" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Sewer Trunk manhole Location
            </NavLink>
          </div>
		  
          <div style={{ margin: '10px' }}>
            <NavLink to="/about" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              About
            </NavLink>
          </div>
		  
		  
          <div style={{ margin: '10px' }}>
            <NavLink to="/Dstp" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Dstp
            </NavLink>
          </div>
		  
          <div style={{ margin: '10px' }}>
            <NavLink to="/Pagla" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Pagla
            </NavLink>
          </div>		  

          <div style={{ margin: '10px' }}>
            <NavLink to="/Mirpur" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Mirpur
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Rayerbazar" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Rayerbazar
            </NavLink>
          </div>

          <div style={{ margin: '10px' }}>
            <NavLink to="/Uttara" style={({ isActive }) => ({
              color: isActive ? 'greenyellow' : 'white'
            })}>
              Uttara
            </NavLink>
          </div>


          </div>
		  

      
       
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route exact path="/about" element={<About />}/>		 
          <Route exact path="/Dstp" element={<Dstp/>} />
		  <Route exact path="/Pagla" element={<Pagla/>} />	 
          <Route exact path="/Mirpur" element={<Mirpur />} />
          <Route exact path="/Rayerbazar" element={<Rayerbazar />} />
          <Route exact path="/Uttara" element={<Uttara />} />
 
		  
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
