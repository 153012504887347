  //  block of code to be executed if the condition is true
//import React from "react";
import React, { Component } from "react";
import { MapContainer, Map, TileLayer, Marker, Popup, LayersControl, Circle, Polygon, LayerGroup, Tooltip } from 'react-leaflet';
import './App.css';
import { Icon } from "leaflet";
import { useMap,useMapEvent,FeatureGroup,GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { useEffect } from 'react';
import north from './north.json';
import south from './south.json';
import zone from './SLS_point.json';
import trunk from './sls_trunk.json';
import  geojsonData from './SLS_point.json';
import  drive from './drive.json';
import crimes from './data.json';
import ZoneBND from './data/ZBD_line.json';


//import Choro from "./Choro";
import "./styles.css";
import "leaflet-arrowheads";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
//import { GeoJSON } from 'react-leaflet/GeoJSON'

import arcades10 from './sls_trunk.json'
//delete L.Icon.Default.prototype._getIconUrl;

import {
	bz61, bz62,bz63,bz64, bz65, bz66,bz67,bz69,bz610,bz68,
Arambag_SLS,Basaboo_SLS,sadgate_SLS, Nilkhet_Newmarket_SLS,
Hazaribag_SLS,
Nawabgonj_SLS,
Azimpur_SLS,
Armanitola_SLS,
Dholaikhal_Narinda_SLS,
Saidabad_Swamibag_SLS,
Goran_SLS,
Motherteck_SLS,
Mohakhali_DOHS,
Zigatola_SLS,
Moghbazar_SLS,
Banani_DOHS,
Dhaka_Medical_College,
Faridabad_SLS,
Mirpur_DOHS,
Old_Airport_SLS,
Narinda_Central_PS,
Japan_Garden_City_SLS,
Tejgaon,
Rampura_Central_PS,

DSTP,
Mirpur_STP,
Rayerbazar_STP,
Uttara_STP,
Pagla_STP,
 
  animalKingdomPolygon,
  hollywoodStudiosPolygon,
  blizzardBeachPolygon,
  epcotCenter,
  magicKingdomCenter,
  magicKingdomCenter1,
} from './data1';
//import * as DTWall from "./data/Biometric.json";
import * as data_z1 from "./data/data_z1.json";

import * as data_z2 from "./data/data_z1.json";
import * as data_z3 from "./data/SLS_point.json";
import * as data_z4 from "./data/data_z1.json";
import * as data_z5 from "./data/data_z1.json";
import * as data_z6 from "./data/data_z1.json";
import * as data_z7 from "./data/data_z1.json";
import * as data_z8 from "./data/data_z1.json";
import * as data_z9 from "./data/drive.json";
import * as data_z10 from "./data/data_z1.json";
import Main_Divertion_sewer from "./data/Hatirjheel/Main_Divertion_sewer.json";
import Main_Divertion_manhole from "./data/Hatirjheel/Main_Divertion_manhole.json";
import SSDS from "./data/Hatirjheel/SSDS.json";

import DSTP_pipe from "./data/DSTP/DSTP_pipe.json";
import DSTP_manhole from "./data/DSTP/DSTP_manhole.json";



import code_trunk from './data/pagla/code_trunk';
//import onEachFeature1 from './data/pagla/code_trunk';
//import nationalParks from "./national-parks.json";
import useSwr from "swr";




const fetcher = (...args) => fetch(...args).then(response => response.json());






const skater = new Icon({
  iconUrl: "/giphy_cir.gif",
  iconSize: [15, 15]
});
/*
const SLS = new Icon({
  iconUrl: "/pride-rainbow.gif",
  iconSize: [15, 15]
});

*/
const DTW = new Icon({
  iconUrl: "/DTW.gif",
  iconSize: [15, 30]
});
const SLS_lift1 = new Icon({
  iconUrl: "/dance.gif",
  iconSize: [30, 55]
});
const SLS_lift = new Icon({
  iconUrl: "/pride-rainbow.gif",
  iconSize: [25, 25]
});


//import * as xxx from "./data/bz6.json";

//var legend1 = legend ;
//import Header from "./components/Header";
//import Content from "./components/Content";
//import { Icon } from "leaflet";
//import * as parkData1 from "./data/skateboard-parks.json";



const GetCoordinates = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const info = L.DomUtil.create('div', 'legend');

    const positon = L.Control.extend({
      options: {
        position: 'bottomright'
      },

      onAdd: function () {
        info.textContent = 'Click on map';
        return info;
      }
    })

    map.on('click', (e) => {
      info.textContent = e.latlng;
    })

	map.addControl(new positon());

  }, [map])


  return null

}

//import * as DTW2 from "./data/output.js";
//var parkData = parkData1;
const DTW1 = data_z1;
const DTW2 = data_z1;
const DTW33 = data_z3;
const DTW4 = data_z1;
const DTW5 = data_z1;
const DTW6 = data_z1;
const DTW7 = data_z1;
const DTW9 = data_z1;
const DTW8 = data_z1;
const DTW10 = data_z1;
const xxxx= arcades10

//const DTW = DTWall;
//console.log(parkData)
const ControllingGroup = () => {
  const map = useMapEvent({
    layeradd() {
      let bounds = new L.LatLngBounds();
      map.eachLayer(function (layer) {
        if (layer instanceof L.FeatureGroup) {
          bounds.extend(layer.getBounds());
        }
      });

      if (bounds.isValid()) {
        map.flyToBounds([bounds]);
      }
    },
  });

  return null;
};

const Cz1 = { color: "green" };
const Cz2 = { color: "blue" };
const Cz3 = { color: "green" };
const Cz4 = { color: "magenta" };
const Cz5 = { color: "red" };
const Cz6 = { color: "Orange" };
const Cz7 = { color: "blue" };
const Cz8 = { color: "green" };
const Cz9 = { color: "magenta" };
const Cz10 = { color: "Orange" };

const redOptions = { color: 'red' }


function getVoivodeshipName(feature, layer1) {
  if (feature.properties && feature.properties.MATERIAL) {
    layer1.bindPopup('<b>' + "Diameter:" +'&nbsp;' +feature.properties.DIMETERMM + '</b><br>'
                         + "Length:" +'&nbsp;' + feature.properties.LENGTHM + '<br>'
                        + "Material:" +'&nbsp;' + feature.properties.MATERIAL+'<br>'
						//+ "contactor:" +'&nbsp;' + feature.properties.hist_occup+'<br>'
						+"Ins-Date:" +'&nbsp;' + feature.properties.created_us); 
  }
}

function onEachFeature1(feature, layer1) {
  layer1.on('mouseover', function (e) {

    getVoivodeshipName(feature, layer1);

    this.openPopup();

    // style
    this.setStyle({
      fillColor: '#eb4034',
      weight: 2,
      color: '#eb4034',
      fillOpacity: 0.7,
    });
  });
  layer1.on('mouseout', function () {
    this.closePopup();
    // style
    this.setStyle({
      fillColor: '#3388ff',
      weight: 2,
      color: '#3388ff',
      fillOpacity: 0.2,
    });
  });
  };
  
  
 function getVoivodeshipName1(feature, layer2) {
  if (feature.properties && feature.properties.type) {
    layer2.bindPopup('<b>' + "Diameter:" +'&nbsp;' +feature.properties.type + '</b><br>'
                         + "Length:" +'&nbsp;' + feature.properties.type + '<br>'
                        + "Material:" +'&nbsp;' + feature.properties.type+'<br>'
						//+ "contactor:" +'&nbsp;' + feature.properties.hist_occup+'<br>'
						+"Ins-Date:" +'&nbsp;' + feature.properties.type); 
  }
}

function onEachFeature2(feature, layer2) {
  layer2.on('mouseover', function (e) {

    getVoivodeshipName1(feature, layer2);

    this.openPopup();

    // style
    this.setStyle({
      fillColor: '#eb4034',
      weight: 2,
      color: '#eb4034',
      fillOpacity: 0.7,
    });
  });
  layer2.on('mouseout', function () {
    this.closePopup();
    // style
    this.setStyle({
      fillColor: '#3388ff',
      weight: 2,
      color: '#3388ff',
      fillOpacity: 0.2,
    });
  });
  }; 

function getVoivodeshipName2(feature, layer3) {
  if (feature.properties && feature.properties.Dia_MM) {
    layer3.bindPopup('<b>' + "Diameter:" +'&nbsp;' +feature.properties.Dia_MM + '</b><br>'
                         + "Length:" +'&nbsp;' + feature.properties.Length + '<br>'
                        + "Material:" +'&nbsp;' + feature.properties.Material+'<br>'
						//+ "contactor:" +'&nbsp;' + feature.properties.hist_occup+'<br>'
						+"Ins-Date:" +'&nbsp;' + feature.properties.Ins_Date); 
  }
}

function onEachFeature3(feature, layer3) {
  layer3.on('onclick', function (e) {

    getVoivodeshipName2(feature, layer3);

    this.openPopup();

    // style
    this.setStyle({
      fillColor: '#eb4034',
      weight: 2,
      color: '#eb4034',
      fillOpacity: 0.7,
    });
  });
  layer3.on('onclick', function () {
    this.closePopup();
    // style
    this.setStyle({
      fillColor: '#3388ff',
      weight: 2,
      color: '#3388ff',
      fillOpacity: 0.2,
    });
  });
  }; 
  
  

 
  
function Zone6() {
	

	

/*
const mapRef = useRef();

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if ( !map ) return;

    const parksGeoJson = new L.GeoJSON(nationalParks, {
      onEachFeature: (feature = {}, layer) => {
        const { properties = {} } = feature;
        const { Name } = properties;

        if ( !Name ) return;

        layer.bindPopup("<p>${Name}</p>");
      }
    });

    parksGeoJson.addTo(map);
  }, [])
*/
  // const arr = DTW2;

  //const result = arr.filter(park => park.properties.ZONEID === 5);



  //const DTW = DTW1.filter(park => park.properties.name === "Gulshan-7")
  return (
      

    
    <MapContainer center={[23.878715, 90.370549]} zoom={15}  scrollWheelZoom={true}>
         <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />

    <GetCoordinates />

		 
      {/*
      {DTW.features.map(park => (

        <Marker key={park.properties.OBJECTID} position={[park.properties.X,
        park.properties.Y]}>
          <Popup position={[park.properties.X,
          park.properties.Y]}>
            <div >
              <h1 style={{ color: "red" }}>Water ATM  Booth LOCATION!</h1>
              <h2>{park.properties.name}</h2>
              <h1 style={{ color: "red" }}>COPEN (10 AM to 6 PM )!</h1>
              <h2>{park.properties.LABEL}</h2>

            </div>

          </Popup>
        </Marker>
      ))}
	   //<Circle className="circle-magic-kingdom" center={magicKingdomCenter} radius={100} />
	     //<Circle className="circle-magic-kingdom" center={magicKingdomCenter1} radius={100} />
	  
    */}
			 
	
	
      <LayersControl position="topright">

        <LayersControl.BaseLayer  name="google street">

          <TileLayer
            url='http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />

        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer checked name="google satelite">

          <TileLayer
            url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
		
		<LayersControl.BaseLayer  name="open street">

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				
				/>
				
        </LayersControl.BaseLayer>	
		
	
		
        <LayersControl.Overlay checked name="Dasherkandi_STP">
         
		  <Circle className="circle-magic-kingdom" center={magicKingdomCenter} radius={200} />
		   </LayersControl.Overlay>
		   <LayersControl.Overlay checked name="pagla_STP">
         
		  <Circle className="circle-magic-kingdom" center={epcotCenter} radius={200} />

	
        </LayersControl.Overlay>
		
		
		<LayersControl.Overlay  name="Trunk main">
          <LayerGroup>
			  
 		 <GeoJSON data={trunk} onEachFeature={onEachFeature1}  />  
		    </LayerGroup>
		    </LayersControl.Overlay>
		
		
		
		
		

        <LayersControl.Overlay  name="manhole_south">
          <LayerGroup>
	   
            <Tooltip sticky>zone 6</Tooltip>
          {south.features.map((arcade1, index) => (
            <Marker
              key={arcade1.properties['start']}
              position={[arcade1.geometry.coordinates[1], arcade1.geometry.coordinates[0]]}icon={skater}>
              <Popup>
              
				
				<div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{arcade1.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{arcade1.properties.Pipe_Diameter}</h2>
					<a href={arcade1.properties.Nearby_Building}><img src={"" } alt="image_link" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
		  
          </LayerGroup>
        </LayersControl.Overlay>
		
		

        <LayersControl.Overlay checked name="manhole_north">
          <LayerGroup>	  
		  
		  {crimes.map(crime => (
              <Marker key={crime.Picture} position={[crime.Latitude,
              crime.Longitude]}icon={skater}>
                <Popup position={[crime.Latitude,
                crime.Longitude]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{crime.Latitude}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{crime.Longitude}</h2>
					<a href={crime.Picture}><img src={"Picture" } alt="Alternative text" width='3' height='4'></img></a>
                  </div>
                </Popup>
              </Marker>		
		
      ))}           
		  
		  
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay  checked name="SLS">
          <LayerGroup>
		  
            
            <Tooltip sticky>zone 6</Tooltip>
			
			
            {zone.features.map((SLS, index) => (
            <Marker
              key={SLS.properties['start']}
              position={[SLS.geometry.coordinates[1], SLS.geometry.coordinates[0]]}icon={SLS_lift1}
            >
              <Popup>
                {SLS.properties.SLS_Name1}
                <br />
                {SLS.properties['SLS_Name1']}
				
				<div >
                    <h1 style={{ color: "red" }}>SLS Name!</h1>
                    <h2>{SLS.properties.SLS_Name}</h2>
                    <h1 style={{ color: "red" }}>Zone!</h1>
                    <h2>{SLS.properties.Zone}</h2>
					
                  </div>
              </Popup>
            </Marker>
          ))}
		  
		
          </LayerGroup>
	       </LayersControl.Overlay>

      </LayersControl>	  



		  
		<LayersControl position="topright">  
		  
        <LayersControl.Overlay  name="zone 4">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz64} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW4.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay  name="zone 5">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz65} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW5.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 6">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz66} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW6.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 7">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz67} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW7.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>


        <LayersControl.Overlay  name="zone 9">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz69} />
            <Tooltip sticky>zone 6</Tooltip>
            {DTW9.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>



        <LayersControl.Overlay  name="zone 10">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz610} />
            <Tooltip sticky>zone 6</Tooltip>
			
            {DTW10.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}
			
          </LayerGroup>
        </LayersControl.Overlay>



        <LayersControl.Overlay  name="zone 8">
          <LayerGroup>
            <Polygon pathOptions={{ color: 'purple', fillColor: 'green' }} positions={bz68} />
            <Tooltip sticky>zone 6</Tooltip>
			{DTW8.features.map(park => (
              <Marker key={park.properties.OBJECTID} position={[park.properties.X,
              park.properties.Y]}>
                <Popup position={[park.properties.X,
                park.properties.Y]}>
                  <div >
					<h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{park.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{park.properties.Pipe_Diame}</h2>
					<a href={park.properties.image_lnk}><img src={park.properties.image_lnk } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
                </Popup>
              </Marker>
            ))}

          </LayerGroup>
        </LayersControl.Overlay>
		
		        

      </LayersControl>
	  
 
		<LayersControl position="topright">  
		
	    <LayersControl.Overlay checked name="DSTP">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay> 
		  
        <LayersControl.Overlay  name="DSTP_manhole">
          <LayerGroup>
            {DSTP_manhole.features.map((arcade, index) => (
            <Marker
              key={arcade.properties['start']}
              position={[arcade.geometry.coordinates[1], arcade.geometry.coordinates[0]]}icon={skater}>
              <Popup>
                
				
				<div >
                    <h1 style={{ color: "red" }}> Manhole Name: </h1> 
                    <h2>{arcade.properties.Manhole_Id}</h2>
					 <h1 style={{ color: "red" }}>GL (m )!</h1>
					 <h2>{arcade.properties.GL}</h2>                
					 <h1 style={{ color: "red" }}>INV_Level(m)!</h1>
                    <h2>{arcade.properties.INV_Level}</h2>
                    <h1 style={{ color: "red" }}>depth (m )!</h1>
                    <h2>{arcade.properties.Depth_M}</h2>
					<h1 style={{ color: "red" }}>Type!</h1>
                    <h2>{arcade.properties.Remarks}</h2>
					<a href={arcade.properties.Land_Mark}><img src={arcade.properties.Land_Mark } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
          </LayerGroup>
        </LayersControl.Overlay>
		
		
		<LayersControl.Overlay  name="DSTP_pipe" collapsed={true}>
          <LayerGroup>
			  

			
	<GeoJSON
    data={DSTP_pipe}
    onEachFeature={(feature, layer3) => {
        		
		if (feature.properties && feature.properties.Dia_MM) {
    layer3.bindPopup('<b>' + "Diameter:" +'&nbsp;' +feature.properties.Dia_MM + '</b><br>'
                         + "Length:" +'&nbsp;' + feature.properties.Length + '<br>'
                        + "Material:" +'&nbsp;' + feature.properties.Material+'<br>'
						+ "contactor:" +'&nbsp;' + feature.properties.contractor+'<br>'
						+"Ins-Date:" +'&nbsp;' + feature.properties.Ins_Date); 
  }
		
    }}	/> 	
		</LayerGroup>	
	 </LayersControl.Overlay>		
			
		
         </LayersControl>
		 
		 
		 
		 
		 

<LayersControl position="topright">  
		
	    <LayersControl.Overlay checked name="HATIR JHEEL">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay> 
		  
        <LayersControl.Overlay  name="Main_Divertion_manhole">
          <LayerGroup>
            {Main_Divertion_manhole.features.map((arcade, index) => (
            <Marker
              key={arcade.properties['start']}
              position={[arcade.geometry.coordinates[1], arcade.geometry.coordinates[0]]}icon={skater}>
              <Popup>
                
				
				<div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{arcade.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{arcade.properties.Pipe_Dia}</h2>
					<a href={arcade.properties.Land_Mark}><img src={arcade.properties.Land_Mark } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
          </LayerGroup>
        </LayersControl.Overlay>
		
		        
		
		
		<LayersControl.Overlay  name="Main_Divertion_sewer" collapsed={true}>
          <LayerGroup>
			  
 		 <GeoJSON data={Main_Divertion_sewer} onEachFeature={onEachFeature2}  />  
		    </LayerGroup>
		    </LayersControl.Overlay>
			
<LayersControl.Overlay   name="SSDS">
          <LayerGroup>
            {SSDS.features.map((arcade, index) => (
            <Marker
              key={arcade.properties['start']}
              position={[arcade.geometry.coordinates[1], arcade.geometry.coordinates[0]]}icon={SLS_lift}>
              <Popup>
                
				
				<div >
                    <h1 style={{ color: "red" }}>Sewer Manhole LOCATION!</h1>
                    <h2>{arcade.properties.Manhole_ID}</h2>
                    <h1 style={{ color: "red" }}>Pipe Diameter (mm )!</h1>
                    <h2>{arcade.properties.Pipe_Dia}</h2>
					<a href={arcade.properties.Land_Mark}><img src={arcade.properties.Land_Mark } alt="Alternative text" width='300' height='400'></img></a>
                  </div>
              </Popup>
            </Marker>
          ))}
          </LayerGroup>
        </LayersControl.Overlay>			
			

			

         </LayersControl>


 
 
<LayersControl position="topleft" collapsed={true}	 >

 
   <LayersControl.Overlay checked name="SEWER_LIFT_STATION">
        <FeatureGroup pathOptions={{ color: 'purple' }}>

          
        </FeatureGroup>
       </LayersControl.Overlay>   


        <LayersControl.Overlay name="Arambag_SLS" >
          <FeatureGroup>
		  
           <Polygon pathOptions={Cz1}  positions={Arambag_SLS} />
		   
          	
		</FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay name="Basaboo_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Basaboo_SLS} />
			</FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  <LayersControl.Overlay name="sadgate_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz3} positions={sadgate_SLS} />
			
	
          </FeatureGroup>
         </LayersControl.Overlay>
      



        <LayersControl.Overlay name="Nilkhet_Newmarket">
          <FeatureGroup>
            <Polygon pathOptions={Cz4} positions={Nilkhet_Newmarket_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  
	 <LayersControl.Overlay name="Hazaribag_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz5} positions={Hazaribag_SLS} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Nawabgonj_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz6} positions={Nawabgonj_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
    
	  
	  
	 <LayersControl.Overlay name="Azimpur_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={Azimpur_SLS} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Armanitola_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={Armanitola_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
     


		<LayersControl.Overlay name="Dholaikhal_Narinda">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={Dholaikhal_Narinda_SLS} />
          </FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay name="Saidabad_Swamibag">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={Saidabad_Swamibag_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		
	  <LayersControl.Overlay name="Goran_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz1} positions={Goran_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>	
		
	  <LayersControl.Overlay name="Motherteck_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={Motherteck_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>	

	  <LayersControl.Overlay name="Mohakhali_DOHS">
          <FeatureGroup>
            <Polygon pathOptions={Cz3} positions={Mohakhali_DOHS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Zigatola_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz4} positions={Zigatola_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Moghbazar_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz5} positions={Moghbazar_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Banani_DOHS">
          <FeatureGroup>
            <Polygon pathOptions={Cz6} positions={Banani_DOHS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Dhaka_Medical">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={Dhaka_Medical_College} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Faridabad_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={Faridabad_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
	  <LayersControl.Overlay name="Mirpur_DOHS">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={Mirpur_DOHS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		  <LayersControl.Overlay name="Old_Airport_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={Old_Airport_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
	<LayersControl.Overlay name="Narinda_Central_PS">
          <FeatureGroup>
            <Polygon pathOptions={Cz1} positions={Narinda_Central_PS} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay name="Japan_Garden_City">
          <FeatureGroup>
            <Polygon pathOptions={Cz1} positions={Japan_Garden_City_SLS} />
          </FeatureGroup>
        </LayersControl.Overlay>	
		
		<LayersControl.Overlay name="Tejgaon_SLS">
          <FeatureGroup>
            <Polygon pathOptions={Cz1} positions={Tejgaon} />
          </FeatureGroup>
        </LayersControl.Overlay>		
		
		<LayersControl.Overlay name="Rampura_Central">
          <FeatureGroup>
            <Polygon pathOptions={Cz1} positions={Rampura_Central_PS} />
          </FeatureGroup>
        </LayersControl.Overlay>	
	
		
      </LayersControl>

	  
	

<LayersControl position="topleft" collapsed={true}>

 <LayersControl.Overlay checked name="MODS ZONE">
        <FeatureGroup pathOptions={{ color: 'purple' }}>
          
        </FeatureGroup>
       </LayersControl.Overlay>  
 
        <LayersControl.Overlay name="Zone1">
          <FeatureGroup>
		  
           <Polygon pathOptions={Cz1} positions={bz61} />
          	
		</FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone2">
          <FeatureGroup>
            <Polygon pathOptions={Cz2} positions={bz62} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  <LayersControl.Overlay name="Zone3">
          <FeatureGroup>
            <Polygon pathOptions={Cz3} positions={bz63} />
          </FeatureGroup>
         </LayersControl.Overlay>
      



        <LayersControl.Overlay name="Zone4">
          <FeatureGroup>
            <Polygon pathOptions={Cz4} positions={bz64} />
          </FeatureGroup>
        </LayersControl.Overlay>
     
	  
	  
	  
	 <LayersControl.Overlay name="Zone5">
          <FeatureGroup>
            <Polygon pathOptions={Cz5} positions={bz65} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone6">
          <FeatureGroup>
            <Polygon pathOptions={Cz6} positions={bz66} />
          </FeatureGroup>
        </LayersControl.Overlay>
    
	  
	  
	 <LayersControl.Overlay name="Zone7">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={bz67} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Zone8">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={bz68} />
          </FeatureGroup>
        </LayersControl.Overlay>
     


		<LayersControl.Overlay name="Zone9">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={bz69} />
          </FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay name="Zone10">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={bz610} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		<LayersControl.Overlay checked name="ZoneBND">
          <LayerGroup>			  
 		 <GeoJSON data={ZoneBND}   />  
		    </LayerGroup>
		    </LayersControl.Overlay>
		

    
	<LayersControl.Overlay  name="Dasherkandi_STP">       
		  <Circle className="circle-magic-kingdom" center={magicKingdomCenter} radius={200} />
		   </LayersControl.Overlay>
		   <LayersControl.Overlay  name="pagla_STP">         
		  <Circle className="circle-magic-kingdom" center={epcotCenter} radius={200} />	
        </LayersControl.Overlay>
		
		
      </LayersControl>
	 
	  
	<LayersControl position="topleft" collapsed={true}>
	
  <LayersControl.Overlay checked name="SEWER_TREATMENT_PLANT">
        <FeatureGroup pathOptions={{ color: 'purple' }}>
          
        </FeatureGroup>
       </LayersControl.Overlay> 
	  
	  <LayersControl.Overlay name="Dasherkandi_STP">
          <FeatureGroup>
            <Polygon pathOptions={Cz7} positions={DSTP} />
          </FeatureGroup>
         </LayersControl.Overlay>




        <LayersControl.Overlay name="Mirpur_STP">
          <FeatureGroup>
            <Polygon pathOptions={Cz8} positions={Mirpur_STP} />
          </FeatureGroup>
        </LayersControl.Overlay>
     


		<LayersControl.Overlay name="Rayerbazar_STP">
          <FeatureGroup>
            <Polygon pathOptions={Cz9} positions={Rayerbazar_STP} />
          </FeatureGroup>
         </LayersControl.Overlay>



        <LayersControl.Overlay checked name="Uttara_STP">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={Uttara_STP} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		  <LayersControl.Overlay name="Pagla_STP">
          <FeatureGroup>
            <Polygon pathOptions={Cz10} positions={Pagla_STP} />
          </FeatureGroup>
        </LayersControl.Overlay>
		
		
		
		
      </LayersControl>  
	
	  
    <ControllingGroup />	  
	  
	  
	  
    </MapContainer >



  );
}






export default Zone6;




//outcome;